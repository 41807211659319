import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

const App = () => {
  return (
    <Router>
      <div className="app">
        <Switch>
          <Route path="/s.do">
            {() =>
              window.location.replace(
                `https://www.drwu.com/pages/scan-qrcode${window.location.search}`
              )
            }
          </Route>
        </Switch>
      </div>
    </Router>
  );
};

export default App;
